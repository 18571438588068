export const updateJobDivV2 =  (job_id, iteration, status_obj, dayjs,job) => {
    // The updateJobDiv composable works of a message (given a message), whereas the updateJobDivV2 composable is given a job_id, iteration, and a status object
    let element_id = 'job_comment_pane_' + job_id;
    let div_element = document.getElementById(element_id);

    if (! div_element) {
        setTimeout(() => {
            // If the job div is not yet created in the DOM, keep trying after 1 second repeately until the job div is created
            updateJobDivV2(job_id, iteration, status_obj, dayjs,job);
        },1000);
        return;
    }

    
    // update the iterations_select_element to show the corresponding iteration
    if (div_element) {
        let iterations_select_element = div_element.querySelector('select');
        if (iterations_select_element != null) {
            if (iterations_select_element.value != iteration) {
                iterations_select_element.value = iteration;
            }
        }    
    }

    // Update job / iteration status
    //console.log("JOB STATUS:", status_obj['state']);
    let status = status_obj['state'];
    let is_single_iteration_job = isSingleIterationJob('job', job);
    let is_multi_iteration_job_finished  = false;
    if (is_single_iteration_job === false) {
        is_multi_iteration_job_finished = isMultiIterationJobCompleted('job', job);
    }

    if (! window.hasOwnProperty('dagknows')) {
        window['dagknows'] = {};
    }
    if (! window['dagknows'].hasOwnProperty('stopped_jobs')) {
        window['dagknows']['stopped_jobs'] = {};
    }

    let show_job_status = false;
    if (window['dagknows']['stopped_jobs'].hasOwnProperty(job_id)) {
        show_job_status = true;
    } if (is_single_iteration_job === true) {
        show_job_status = true;
    } else if (is_multi_iteration_job_finished === true) {
        show_job_status = true;
    }

    if (window['dagknows']['stopped_jobs'].hasOwnProperty(job_id)) {
        div_element.querySelector('.job_status').innerText = 'STOPISSUED';
    } else if (['finished'].includes(status.toLowerCase())) {
        div_element.querySelector('.job_status').innerText = 'COMPLETED';
    } else {
        div_element.querySelector('.job_status').innerText = status;
    }

    let can_show_stop_job_btn = false;
    let space_dropdown_state = useSpaceDropdownState();
    if (hasPrivilege('job.stop', space_dropdown_state.value)) {
        can_show_stop_job_btn = true;
    }


    if (job['job']['status']['state'] == 'STOPISSUED') {
        window['dagknows']['stopped_jobs'][job_id] = true;
        div_element.querySelector('.job_status_row').style.display='';
        div_element.querySelector('.job_status_row').querySelector('.job_status').innerText = 'STOPISSUED';
        if (can_show_stop_job_btn) {
            div_element.querySelector('.stop_job_btn_container').style.display = 'none';
        }
    }      

    if (show_job_status) {
        div_element.querySelector('.job_status_row').style.display = '';
    } else {
        div_element.querySelector('.job_status_row').style.display = 'none';
    }

    if (['COMPLETED','STOPISSUED'].includes(div_element.querySelector('.job_status').innerText)) {
        if (div_element.querySelector('.stop_job_btn_container') != null) {
            if (can_show_stop_job_btn) {
                div_element.querySelector('.stop_job_btn_container').style.display = 'none';
            }
        }
    }

    let job_id_iter = `${job_id}_${iteration}`;

    if (! window['dagknows']['job_iteration_info'].hasOwnProperty(job_id_iter)) {
        window['dagknows']['job_iteration_info'][job_id_iter] = {};
        let start_finish_time = getIterationStartAndEndTimeFromExecResult(job, dayjs);
        window['dagknows']['job_iteration_info'][job_id_iter]['started_at'] = start_finish_time['started_at'];
        window['dagknows']['job_iteration_info'][job_id_iter]['finished_at'] = start_finish_time['finished_at'];
    }

    // update job start and end time 
    if (is_single_iteration_job) {
        let started_at = isDkValidDate(job['job']['status']['started_at']) || 
            isDkValidDate(job['job']['created_at']);
        let finished_at = isDkValidDate(job['job']['status']['finished_at']) || 
            isDkValidDate(job['job']['status']['stop_issued_at']) || 
            isDkValidDate(job['job']['updated_at']);

        if (['SUBMITTED','READY'].includes(job['job']['status']['state'])) {
            finished_at = '';
        }

        formatTimeForDom(started_at, dayjs, div_element.querySelector('.job_start_time'));
        formatTimeForDom(finished_at, dayjs, div_element.querySelector('.job_end_time'));

        $(div_element).find('.job_start_time_row').show();
        $(div_element).find('.job_end_time_row').show();
    } else {
        let started_at = isDkValidDate(job['job']['schedule']['start_at']) || 
            isDkValidDate(job['job']['created_at']);
        formatTimeForDom(started_at, dayjs, div_element.querySelector('.job_start_time'));
        $(div_element).find('.job_start_time_row').show();

        if (is_multi_iteration_job_finished === true) {
            let finished_at = isDkValidDate(status_obj['finished_at']) || 
                isDkValidDate(job['job']['status']['stop_issued_at']) || 
                isDkValidDate(job['job']['updated_at']);
            formatTimeForDom(finished_at, dayjs, div_element.querySelector('.job_end_time'));    
            $(div_element).find('.job_end_time_row').show();    
        }
    }

    // update iteration start and end time (only applicable to multi-iteration jobs)
    if (! is_single_iteration_job) {
        try {
            let started_at = isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['started_at']) ||
                isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['updated_at']);

            formatTimeForDom(started_at, dayjs, div_element.querySelector('.start_time'));

            let finished_at = isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['finished_at']) ||
                isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['updated_at']);

            formatTimeForDom(finished_at, dayjs, div_element.querySelector('.end_time'));

            $(div_element).find('.iteration_start_end_time_row').show();
            $(div_element).find('.iteration_start_end_time_row').show();

        } catch (e) {
            console.warn(`Iteration Start and End time).  updateJobDiv2.  Exception (SSE or WebSocket):`, e, 'job:', job);
        }
    }

    // Deal with the ABORTED stuff
    if (job?.job.aborted_at) {
        if (is_single_iteration_job) {
            div_element.querySelector('.job_status').innerText = 'ABORTED';
        } else {
            try {
                let started_at = isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['started_at']) ||
                    isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['updated_at']);
                let started_at_epoch = convertTimeToEpoch(started_at, dayjs);
    
                let finished_at = isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['finished_at']) ||
                    isDkValidDate(window['dagknows']['job_iteration_info'][job_id_iter]['updated_at']);
                let finished_at_epoch = convertTimeToEpoch(finished_at, dayjs);

                let aborted_at_epoch = convertTimeToEpoch(job.job.aborted_at, dayjs);
                if ((aborted_at_epoch >= started_at_epoch) && (aborted_at_epoch <= finished_at_epoch)) {
                    div_element.querySelector('.job_status').innerText = 'ABORTED';
                }
            } catch (e) {
                console.warn(`Iteration Start and End time).  updateJobDiv2.  Exception (SSE or WebSocket):`, e, 'job:', job);
            }    
        }
    }
}
